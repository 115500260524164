import React, { useState, useEffect, useContext } from "react";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import { PDFViewer } from "@react-pdf/renderer";
import { isArray, isEmpty } from "lodash";

import Layout from "../../../components/layout";
import { SubmissionState } from "../../../context/SubmissionContext";
import { AuthenticationState } from "../../../context/AuthenticationContext";
import getApiData from "../../../lib/getApiData";
import H1 from "../../../components/htmlElements/h1";
import MyDocument from "../../../helpers/pdf/pdfDocument";

import { DictionaryDataState } from "../../../context/DictionaryDataContext";
import LoadingIcon from "../../../components/loadingIcon";

export default function SummaryPdfPage(props: PageProps) {
  // Extract variables from props
  const { id: materialId } = props;

  const authenticationState = useContext(AuthenticationState) || {
    token: "",
    isAuthenticated: false,
  };

  const dictionaryDataState: any = useContext(DictionaryDataState) || [];

  const { token, isAuthenticated } = authenticationState;

  const submissionState = useContext(SubmissionState) || "";
  const [loaded, setLoaded] = useState<boolean>(false);
  const [materialTitle, setMaterialTitle] = useState<string>("");
  const [metadata, setMetadata] = useState<any>({});
  const [totalPages, setTotalPages] = useState<number>(1);

  /**
   * Fetch material details (metadata)
   */
  useEffect(() => {
    (async () => {
      const details = await getApiData({
        endpoint: `materials/${materialId}`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-Amz-Security-Token": isAuthenticated ? token : "",
        },
      });

      if (details && details.status === 200) {
        setMetadata(details.data);
      }
    })();
  }, [materialId]);

  /**
   * Once metadata is loaded, fetch the material title if available.
   */
  useEffect(() => {
    if (!isEmpty(metadata)) {
      // IRIS Title from `instrument`
      if (
        metadata &&
        metadata.materialMetadata &&
        metadata.materialMetadata.instrument &&
        isArray(metadata.materialMetadata.instrument.title)
      ) {
        if (
          typeof metadata.materialMetadata?.instrument?.title[0] === "object"
        ) {
          if (metadata.materialMetadata.instrument.title[0]["#text"]) {
            setMaterialTitle(
              metadata.materialMetadata.instrument.title[0]["#text"]
            );
          }
        } else {
          setMaterialTitle(metadata.materialMetadata?.instrument?.title[0]);
        }
      } else {
        // We don't have title in instrument. So load record details without it.
      }

      // OASIS Title from `summary`
      if (
        metadata &&
        metadata.materialMetadata &&
        metadata.materialMetadata.summary &&
        metadata.materialMetadata.summary.summaryTitle
      ) {
        if (
          typeof metadata.materialMetadata?.summary?.summaryTitle === "object"
        ) {
          if (metadata.materialMetadata.summary.summaryTitle[0]["#text"]) {
            setMaterialTitle(
              metadata.materialMetadata.summary.summaryTitle[0]["#text"]
            );
          }
        } else {
          setMaterialTitle(metadata.materialMetadata?.summary?.summaryTitle);
        }
      } else {
        // We don't have title in summary. So load record details without it.
      }

      // When all done, set loaded to true
      setLoaded(true);
    }
  }, [metadata]);

  /**
   * In order to make this work during the build we need to use this fix:
   * https://stackoverflow.com/questions/76007339/server-error-error-pdfdownloadlink-is-a-web-specific-api-youre-either-using-t
   */
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  const {
    oasisFooterText,
    oasisHeadingsWhatIsResearchAbout,
    oasisHeadingsWhatReseachersDid,
    oasisHeadingsWhatTheResearchersFound,
    oasisHeadingsThingsToConsider,
    oasisHeadingHowToCite,
    oasisHeadingMaterialsAndAnalysis,
  } = useStaticQuery(graphql`
    query {
      oasisFooterText: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-pdf-footer" } }
      ) {
        frontmatter {
          footer_line_1
          footer_line_2
        }
      }

      oasisHeadingsWhatIsResearchAbout: markdownRemark(
        frontmatter: {
          pageId: { eq: "OASIS-pdf-headings-1-what-is-research-about" }
        }
      ) {
        frontmatter {
          header_Abkhazian
          header_Afar
          header_Afrikaans
          header_Albanian
          header_Amharic
          header_Arabic
          header_Basque
          header_Catalan
          header_Chinese
          header_Dutch
          header_English
          header_Finnish
          header_French
          header_German
          header_Hebrew
          header_Hindi
          header_Italian
          header_Japanese
          header_Khmer
          header_Korean
          header_Mandarin
          header_Nepali
          header_Other
          header_Polish
          header_Portuguese
          header_Sindhi
          header_Spanish
          header_Tamil
          header_Telugu
          header_Thai
          header_Turkish
          header_Vietnamese
        }
      }

      oasisHeadingsWhatReseachersDid: markdownRemark(
        frontmatter: {
          pageId: { eq: "OASIS-pdf-headings-2-what-the-reseachers-did" }
        }
      ) {
        frontmatter {
          header_Abkhazian
          header_Afar
          header_Afrikaans
          header_Albanian
          header_Amharic
          header_Arabic
          header_Basque
          header_Catalan
          header_Chinese
          header_Dutch
          header_English
          header_Finnish
          header_French
          header_German
          header_Hebrew
          header_Hindi
          header_Italian
          header_Japanese
          header_Khmer
          header_Korean
          header_Mandarin
          header_Nepali
          header_Other
          header_Polish
          header_Portuguese
          header_Sindhi
          header_Spanish
          header_Tamil
          header_Telugu
          header_Thai
          header_Turkish
          header_Vietnamese
        }
      }

      oasisHeadingsWhatTheResearchersFound: markdownRemark(
        frontmatter: {
          pageId: { eq: "OASIS-pdf-headings-3-what-the-reseachers-found" }
        }
      ) {
        frontmatter {
          header_Abkhazian
          header_Afar
          header_Afrikaans
          header_Albanian
          header_Amharic
          header_Arabic
          header_Basque
          header_Catalan
          header_Chinese
          header_Dutch
          header_English
          header_Finnish
          header_French
          header_German
          header_Hebrew
          header_Hindi
          header_Italian
          header_Japanese
          header_Khmer
          header_Korean
          header_Mandarin
          header_Nepali
          header_Other
          header_Polish
          header_Portuguese
          header_Sindhi
          header_Spanish
          header_Tamil
          header_Telugu
          header_Thai
          header_Turkish
          header_Vietnamese
        }
      }

      oasisHeadingsThingsToConsider: markdownRemark(
        frontmatter: {
          pageId: { eq: "OASIS-pdf-headings-4-things-to-consider" }
        }
      ) {
        frontmatter {
          header_Abkhazian
          header_Afar
          header_Afrikaans
          header_Albanian
          header_Amharic
          header_Arabic
          header_Basque
          header_Catalan
          header_Chinese
          header_Dutch
          header_English
          header_Finnish
          header_French
          header_German
          header_Hebrew
          header_Hindi
          header_Italian
          header_Japanese
          header_Khmer
          header_Korean
          header_Mandarin
          header_Nepali
          header_Other
          header_Polish
          header_Portuguese
          header_Sindhi
          header_Spanish
          header_Tamil
          header_Telugu
          header_Thai
          header_Turkish
          header_Vietnamese
        }
      }

      oasisHeadingHowToCite: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-pdf-heading-how-to-cite" } }
      ) {
        frontmatter {
          header_Abkhazian
          header_Afar
          header_Afrikaans
          header_Albanian
          header_Amharic
          header_Arabic
          header_Basque
          header_Catalan
          header_Chinese
          header_Dutch
          header_English
          header_Finnish
          header_French
          header_German
          header_Hebrew
          header_Hindi
          header_Italian
          header_Japanese
          header_Khmer
          header_Korean
          header_Mandarin
          header_Nepali
          header_Other
          header_Polish
          header_Portuguese
          header_Sindhi
          header_Spanish
          header_Tamil
          header_Telugu
          header_Thai
          header_Turkish
          header_Vietnamese
        }
      }

      oasisHeadingMaterialsAndAnalysis: markdownRemark(
        frontmatter: {
          pageId: {
            eq: "OASIS-pdf-heading-materials-and-analysis-scripts-available"
          }
        }
      ) {
        frontmatter {
          header_Abkhazian
          header_Afar
          header_Afrikaans
          header_Albanian
          header_Amharic
          header_Arabic
          header_Basque
          header_Catalan
          header_Chinese
          header_Dutch
          header_English
          header_Finnish
          header_French
          header_German
          header_Hebrew
          header_Hindi
          header_Italian
          header_Japanese
          header_Khmer
          header_Korean
          header_Mandarin
          header_Nepali
          header_Other
          header_Polish
          header_Portuguese
          header_Sindhi
          header_Spanish
          header_Tamil
          header_Telugu
          header_Thai
          header_Turkish
          header_Vietnamese
        }
      }
    }
  `);

  return (
    <Layout>
      <H1 innerContent={materialTitle} additionalClasses="mb-5" />

      {isClient && loaded && metadata && dictionaryDataState.oasisAuthors ? (
        <PDFViewer width="100%" height="1200px" showToolbar>
          <MyDocument
            materialMetadata={metadata.materialMetadata}
            objectMetadata={metadata.objectMetadata}
            setTotalPages={setTotalPages}
            dictionaryDataState={dictionaryDataState}
            materialId={materialId}
            materialTitle={materialTitle}
            footerText={oasisFooterText}
            oasisHeadingsWhatIsResearchAbout={oasisHeadingsWhatIsResearchAbout}
            oasisHeadingsWhatReseachersDid={oasisHeadingsWhatReseachersDid}
            oasisHeadingsWhatTheResearchersFound={
              oasisHeadingsWhatTheResearchersFound
            }
            oasisHeadingsThingsToConsider={oasisHeadingsThingsToConsider}
            oasisHeadingHowToCite={oasisHeadingHowToCite}
            oasisHeadingMaterialsAndAnalysis={oasisHeadingMaterialsAndAnalysis}
          />
        </PDFViewer>
      ) : (
        <LoadingIcon textBeforeIcon="Creating PDF" />
      )}
    </Layout>
  );
}
